.stats-view {
  height: 100%;
  position: relative;
  overflow: auto;
}

.stats-chart {
  height: calc(100vh / 7);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 3rem;
}

.bar {
  background-color: var(--correct-status-color);
  flex: 1;
  margin: 0.25rem;
  position: relative;

  text-align: center;
  font-weight: bold;

  padding-top: 0.5rem;
}

.bar.bar-null {
  height: 0.2rem !important;
  background-color: var(--incorrect-status-color);

  padding-top: 0;
}

.bar-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 0.25rem));
}

.keyboard {
  flex: 0 0 auto;

  margin: 0.5rem 0.175rem 0.25rem;
}

.keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.key {
  height: 3.75rem;
  max-width: 3.75rem;
  flex: 1;
  margin: 0.175rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  background-color: var(--unguessed-status-color);
  border: none;
  border-radius: 0.25rem;

  font-size: 1rem;
  font-weight: bold;

  cursor: pointer;
  user-select: none;
}

.key.half-key {
  flex: 0.5 0.5 0%;
  margin: 0;
  background-color: var(--background-color);
}

.key.long-key {
  font-size: 1.25rem;
  max-width: 5.75rem;
}

.key.correct {
  background-color: var(--correct-status-color);
}

.key.inword {
  background-color: var(--inword-status-color);
}

.key.incorrect {
  background-color: var(--incorrect-status-color);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@600&display=swap');

.header {
  height: 100%;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Roboto Slab', serif;
  position: relative;

  background-color: var(--primary-color);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header .header-btn {
  font-size: 1.5rem;
  height: 3.5rem;
  width: 3.5rem;
  position: relative;
}

.header .header-btn svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-home-btn {
  left: 0;
}

/* .header-stats-btn-holder {
  position: absolute;
  right: 3.5rem;
} */

.header-settings-btn {
  right: 0;
}

.header-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.env-badge {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  transform: translate(100%, 0%);
  font-size: 0.8rem;
  background-color: red;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  font-family: sans-serif;
}
